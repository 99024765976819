@import "../../variables/variables";

.resources_wrapper {
  min-height: 40vh;
  height: auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 0 0 0;
  border-bottom: 1px solid #e2e2e2;
  .resources_container {
    width: calc(100% - $sides);
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    h1 {
      width: 593px;
      font-size: 2.0833333333333335vw;
      font-style: normal;
      font-weight: 700;
      line-height: 121.523%;
      letter-spacing: -0.1vw;
      strong {
        color: $color;
      }
    }
    .slide_container {
      width: 100%;
      min-height: 410px;
      height: auto;
      margin-top: 50px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .slide_left {
        width: 65%;
        height: 100%;
        p {
          font-size: 1.1vw;
        }

        .topics {
          // min-height: 8.854166666666666vw;
          // height: auto;
          // border: 1px solid #ff0028;
        }

        .icons_container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 50px;
          .box_icon {
            height: 70px;
            width: 70px;
            border: 1px solid $color;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1.25vw;
            cursor: pointer;
            transition: all ease 0.2s;
            svg,
            path,
            rect {
              fill: $color !important;
            }
          }
        }
      }
      .slide_right {
        width: 35%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          padding: 100px;
          border: 1px solid $color;
          border-radius: 1.0416666666666667vw;
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 800px) {
  .resources_wrapper {
    .resources_container {
      h1 {
        width: 100%;
        font-size: 6vw;
        line-height:7vw ;
        strong {
          color: $color;
        }
      }
      .slide_container {
        .slide_left {
          width: 100%;

          p {
            font-size: 3.9375vw;
          }

          .topics {
          }

          .icons_container {
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-top: 0px;
            .box_icon {
              height: 60px;
              width: 60px;
              margin-bottom: 10px;
              svg {
                width: 25px;
              }
            }
          }
        }
        .slide_right {
          display: none;
        }
      }
    }
  }
}
