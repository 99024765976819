@import '../../variables/variables';
.menu_wrapper{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FF3D00;
    .menu_container{
        width: calc(100% - $sides);
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .logo{
            img{
                width: 80px;

            }
        }
    }
}