@import "../../variables/variables";
.info_wrapper {
  min-height: 10vh;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  .info_container {
    width: calc(100% - $sides);
    height: 100%;
    h1 {
        font-size: 2.0833333333333335vw;
        letter-spacing: -0.1vw;
      strong {
        color: $color;
      }
    }
    p {
        font-size: 1.1vw;
      margin: 40px 0;
    }
  }
}

@media (min-width: 0px) and (max-width: 800px) {
    .info_wrapper {
        min-height: 10vh;
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0;
        .info_container {
          width: calc(100% - $sides);
          height: 100%;
          h1 {
              font-size: 6vw;
              line-height:7vw ;
            strong {
              color: $color;
            }
          }
          p {
            font-size:  4.9375vw;
            margin: 20px 0;
          }
        }
      }
}
