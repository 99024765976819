.banner_wrapper {
  width: 100%;
  min-height: 500px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 0;
  border-bottom: 1px solid #e2e2e2;
}
.banner_wrapper .banner_container {
  width: calc(100% - 20vw);
  height: 100%;
}
.banner_wrapper .banner_container .banner_sides {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.banner_wrapper .banner_container .banner_sides .left_side {
  width: 65%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.banner_wrapper .banner_container .banner_sides .left_side h1 {
  width: 100%;
  color: #000;
  font-size: 3.75vw;
  font-style: normal;
  font-weight: 700;
  line-height: 103.023%;
  letter-spacing: -0.2083333333vw;
}
.banner_wrapper .banner_container .banner_sides .left_side h1 strong {
  color: #FF2E00;
}
.banner_wrapper .banner_container .banner_sides .left_side p {
  width: 95%;
  color: #272727;
  font-size: 1.3020833333vw;
  font-style: normal;
  font-weight: 400;
  line-height: 173.523%; /* 43.381px */
  margin-top: 60px;
  margin-bottom: 60px;
}
.banner_wrapper .banner_container .banner_sides .right_side {
  width: 35%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.banner_wrapper .banner_container .banner_sides .right_side img {
  width: 80%;
  position: relative;
  top: -50px;
}
.banner_wrapper .banner_container .banner_options_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.banner_wrapper .banner_container .banner_options_container .option_block {
  width: 22.1354166667vw;
  height: 9.6354166667vw;
  border-radius: 1.0416666667vw;
  border: 1px solid #FF2E00;
  padding: 0 10px 0 45px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.banner_wrapper .banner_container .banner_options_container .option_block .box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -1.5625vw;
  width: 3.125vw;
  height: 3.125vw;
  border-radius: 3.125vw;
  background-color: #FF2E00;
  top: 50%;
  transform: translateY(-50%);
}
.banner_wrapper .banner_container .banner_options_container .option_block .box svg {
  width: 50%;
}
.banner_wrapper .banner_container .banner_options_container .option_block h3 {
  color: #FF2E00;
  margin-bottom: 20px;
  font-size: 1.1458333333vw;
}
.banner_wrapper .banner_container .banner_options_container .option_block p {
  font-size: 1.0416666667vw;
  padding: 0;
  margin: 0;
}

@media (min-width: 0px) and (max-width: 800px) {
  .banner_wrapper {
    padding: 8.6458333333vw 0;
  }
  .banner_wrapper .banner_container .banner_sides .left_side {
    width: 100%;
  }
  .banner_wrapper .banner_container .banner_sides .left_side h1 {
    width: 100%;
    color: #000;
    font-size: 7vw;
  }
  .banner_wrapper .banner_container .banner_sides .left_side h1 strong {
    color: #FF2E00;
  }
  .banner_wrapper .banner_container .banner_sides .left_side p {
    width: 95%;
    color: #272727;
    font-size: 4.3020833333vw;
    font-style: normal;
    font-weight: 400;
    line-height: 173.523%; /* 43.381px */
    margin-top: 3.125vw;
    margin-bottom: 3.125vw;
  }
  .banner_wrapper .banner_container .banner_sides .right_side {
    display: none;
  }
  .banner_wrapper .banner_container .banner_options_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .banner_wrapper .banner_container .banner_options_container .option_block {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    padding: 20px 10px 20px 45px;
    border-radius: 2.0416666667vw;
  }
  .banner_wrapper .banner_container .banner_options_container .option_block .box {
    left: -5.075vw;
    width: 10.125vw;
    height: 10.125vw;
    border-radius: 6.125vw;
  }
  .banner_wrapper .banner_container .banner_options_container .option_block .box svg {
    width: 50%;
  }
  .banner_wrapper .banner_container .banner_options_container .option_block h3 {
    margin-bottom: 10px;
    font-size: 4.1458333333vw;
  }
  .banner_wrapper .banner_container .banner_options_container .option_block p {
    font-size: 4.0416666667vw;
  }
}/*# sourceMappingURL=style.css.map */