.info_wrapper {
  min-height: 10vh;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}
.info_wrapper .info_container {
  width: calc(100% - 20vw);
  height: 100%;
}
.info_wrapper .info_container h1 {
  font-size: 2.0833333333vw;
  letter-spacing: -0.1vw;
}
.info_wrapper .info_container h1 strong {
  color: #FF2E00;
}
.info_wrapper .info_container p {
  font-size: 1.1vw;
  margin: 40px 0;
}

@media (min-width: 0px) and (max-width: 800px) {
  .info_wrapper {
    min-height: 10vh;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
  }
  .info_wrapper .info_container {
    width: calc(100% - 20vw);
    height: 100%;
  }
  .info_wrapper .info_container h1 {
    font-size: 6vw;
    line-height: 7vw;
  }
  .info_wrapper .info_container h1 strong {
    color: #FF2E00;
  }
  .info_wrapper .info_container p {
    font-size: 4.9375vw;
    margin: 20px 0;
  }
}/*# sourceMappingURL=style.css.map */