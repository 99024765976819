@import "../../variables/variables";
.banner_wrapper {
  width: 100%;
  min-height: 500px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 0;
  border-bottom: 1px solid #e2e2e2;
  .banner_container {
    width: calc(100% - $sides);
    height: 100%;
    .banner_sides {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .left_side {
        width: 65%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        h1 {
          width: 100%;
          color: #000;
          font-size: 3.75vw;
          font-style: normal;
          font-weight: 700;
          line-height: 103.023%;
          letter-spacing: -0.20833333333333334vw;
          strong {
            color: $color;
          }
        }
        p {
          width: 95%;
          color: #272727;
          font-size: 1.3020833333333333vw;
          font-style: normal;
          font-weight: 400;
          line-height: 173.523%; /* 43.381px */
          margin-top: 60px;
          margin-bottom: 60px;
        }
      }

      .right_side {
        width: 35%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        img {
          width: 80%;
          position: relative;
          top: -50px;
        }
      }
    }
    .banner_options_container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .option_block {
        width: 22.135416666666668vw;
        height: 9.635416666666666vw;
        border-radius: 1.0416666666666667vw;
        border: 1px solid $color;
        padding: 0 10px 0 45px;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        .box {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: -1.5625vw;
          width: 3.125vw;
          height: 3.125vw;
          border-radius: 3.125vw;
          background-color: $color;
          top: 50%;
          transform: translateY(-50%);
          svg {
            width: 50%;
          }
        }
        h3 {
          color: $color;
          margin-bottom: 20px;

          font-size: 1.1458333333333333vw;
        }
        p {
          font-size: 1.0416666666666667vw;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 800px) {
  .banner_wrapper {
    padding: 8.6458333333333335vw 0;
    .banner_container {
      .banner_sides {
        .left_side {
          h1 {
            width: 100%;
            color: #000;
            font-size: 7vw;
            strong {
              color: $color;
            }
          }
          p {
            width: 95%;
            color: #272727;
            font-size: 4.3020833333333333vw;
            font-style: normal;
            font-weight: 400;
            line-height: 173.523%; /* 43.381px */
            margin-top: 3.125vw;
            margin-bottom: 3.125vw;
          }
          width: 100%;
        }

        .right_side {
          display: none;
        }
      }
      .banner_options_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        .option_block {
          width: 100%;
          height: auto;
          margin-bottom: 10px;
          padding: 20px 10px 20px 45px;
          border-radius: 2.0416666666666667vw;

          .box {
            left: -5.075vw;
            width: 10.125vw;
            height: 10.125vw;
            border-radius: 6.125vw;
            svg {
              width: 50%;
            }
          }
          h3 {
            margin-bottom: 10px;

            font-size: 4.1458333333333333vw;
          }
          p {
            font-size: 4.0416666666666667vw;
          }
        }
      }
    }
  }
}
